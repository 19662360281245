<template>
  <div class="no-data">
    <div>
      <img src="../../assets/images/noData.png" style="width: 70px; height: 60px; margin-bottom: 8px" />

      <span>暂无数据</span>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.no-data {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
