<template>
  <div class="healthyIndex box box_column">
    <!-- 头部导航 -->
    <div class="box justify mB10">
      <div>
        <ul class="ulTab box center">
          <li v-for="(item, index) in list" :key="index" class="box center" @click="activeTab(item)">
            <div style="width: 100%" class="fu">
              <div>{{ item.name }}</div>
              <div class="tiao" v-if="active == item.id"></div>
            </div>
            <div class="shu" v-if="index != list.length - 1"></div>
          </li>
        </ul>
      </div>
      <div @click="getSet" class="pointer btnSelect2 health-btn" :class="!myOption ? 'disable_icon' : ''">健康监测设置</div>
    </div>

    <!-- <div class="jieduan"></div> -->

    <!-- <div class="box box_column"> -->

    <div class="healty-con">
      <div class="healty-con-left">
        <!-- <div class="box mT10 charts-h">
            <div class="flex1 charts-position" style="margin-right: 24px">
              <div id="history-health" class="h100" v-show="!noHistory"></div>
              <div class="charts-title" v-show="!noHistory">历史健康监测</div>
            
              <div class="no-his-data" v-show="noHistory">暂无数据...</div>
            </div>
            <div class="flex1 charts-position h100" v-show="!noChartsData">
              <div class="charts-title">{{ healthyTestTitle }}健康监测</div>
              <div class="chart-status charts-b-t" :class="{ 'chart-status-1': totalScore == 1, 'chart-status-2': totalScore == 2, 'chart-status-3': totalScore == 3, 'chart-status-4': totalScore == 4, 'chart-status-5': totalScore == 5 }">技术状况</div>
            </div>
            <div class="flex1 charts-position h100 chart-line" v-show="!noChartsData">
              <div id="chart-top-weight" class="h270"></div>
              <div class="charts-b-t">上部结构权重</div>
            </div>
            <div class="flex1 charts-position h100" v-show="!noChartsData">
              <div id="chart-bottom-weight" class="h270"></div>
              <div class="charts-b-t">下部结构权重</div>
            </div>
            <div class="charts-position h100" style="flex: 3" v-show="noChartsData">
              <div class="no-his-data">暂无数据...</div>
            </div>
          </div>
          -->
        <div class="health-month health-bgc">
          <textIcon :text="(constructData.timeO || list[active - 1].name) + '健康监测'"></textIcon>
          <div class="health-month-con health-bgc-con">
            <div class="health-month-con-item box w336">
              <div class="health-month-con-item-text">
                <div class="health-month-con-item-first box">
                  <div class="health-month-con-item-first-t">状况评估</div>
                  <div class="health-month-con-item-first-score w86">{{ constructData.score ? parseInt(constructData.score) : "" }}<span class="score">分</span></div>
                </div>
                <div class="health-month-con-item-second box">
                  <div class="health-month-con-item-second-t">健康监测</div>
                  <div class="health-month-con-item-second-score w86">{{ constructData.health && constructData.health.score ? parseInt(constructData.health.score) : "" }}<span class="score">分</span></div>
                </div>

                <div class="health-month-con-item-second box">
                  <div class="health-month-con-item-second-t">定期检查</div>
                  <div class="health-month-con-item-second-score w86">
                    <span v-if="constructData.regular && constructData.regular.score">{{ constructData.regular && constructData.regular.score ? parseInt(constructData.regular.score) : "" }}<span class="score">分</span></span>
                    <span v-else class="score">未上传</span>
                  </div>
                </div>
              </div>
              <div class="health-month-con-item-img">
                <div
                  class="health-month-con-status charts-b-t"
                  :class="{ 'chart-status-1': constructData.technical == 1, 'chart-status-2': constructData.technical == 2, 'chart-status-3': constructData.technical == 3, 'chart-status-4': constructData.technical == 4, 'chart-status-5': constructData.technical == 5 }"
                ></div>
              </div>
            </div>
            <div class="line"></div>
            <div class="health-month-con-item flex1">
              <div class="health-month-con-item-first box">
                <div class="health-month-con-item-first-t">健康监测</div>
                <div class="health-month-con-item-first-score w63">
                  <span class="health-date">{{ constructData.health && constructData.health.timeO ? constructData.health.timeO : "" }}</span>
                </div>
              </div>
              <div class="health-month-con-item-second box">
                <div class="health-month-con-item-second-t">下部结构SBCI</div>
                <div class="health-month-con-item-second-score w63">
                  <span>{{ constructData.health && constructData.health.sbci ? parseInt(constructData.health.sbci) : "" }}</span>
                  <span class="score">分</span>
                </div>
              </div>

              <div class="health-month-con-item-second box">
                <div class="health-month-con-item-second-t">上部结构SPCI</div>
                <div class="health-month-con-item-second-score w63">
                  <span>{{ constructData.health && constructData.health.spci ? parseInt(constructData.health.spci) : "" }}</span>
                  <span class="score">分</span>
                </div>
              </div>
              <div class="health-month-con-item-second box">
                <div class="health-month-con-item-second-t">桥面系BDCI</div>
                <div class="health-month-con-item-second-score w63">
                  <span>{{ constructData.health && constructData.health.bdci ? parseInt(constructData.health.bdci) : "" }}</span>
                  <span class="score">分</span>
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div class="health-month-con-item flex1">
              <div class="health-month-con-item-first box">
                <div class="health-month-con-item-first-t">定期检查</div>
                <div class="health-month-con-item-first-score w63">
                  <span class="health-date">{{ constructData.regular && constructData.regular.timeO ? constructData.regular.timeO : "" }}</span>
                </div>
              </div>
              <div class="health-month-con-item-second box">
                <div class="health-month-con-item-second-t">下部结构SBCI</div>
                <div class="health-month-con-item-second-score w63">
                  <span v-if="constructData.regular && constructData.regular.sbci">
                    <span>{{ constructData.regular && constructData.regular.sbci ? parseInt(constructData.regular.sbci) : "" }}</span>
                    <span class="score">分</span>
                  </span>
                  <span v-else class="score">未上传</span>
                </div>
              </div>

              <div class="health-month-con-item-second box">
                <div class="health-month-con-item-second-t">上部结构SPCI</div>
                <div class="health-month-con-item-second-score w63">
                  <span v-if="constructData.regular && constructData.regular.spci">
                    <span>{{ constructData.regular && constructData.regular.spci ? parseInt(constructData.regular.spci) : "" }}</span>
                    <span class="score">分</span>
                  </span>

                  <span v-else class="score">未上传</span>
                </div>
              </div>
              <div class="health-month-con-item-second box">
                <div class="health-month-con-item-second-t">桥面系BDCI</div>
                <div class="health-month-con-item-second-score w63">
                  <span v-if="constructData.regular && constructData.regular.bdci">
                    <span>{{ constructData.regular && constructData.regular.bdci ? parseInt(constructData.regular.bdci) : "" }}</span>
                    <span class="score">分</span>
                  </span>
                  <span v-else class="score">未上传</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="health-charts">
          <div class="health-charts-left flex1 health-bgc">
            <div class="flex1 box health-charts-left-item">
              <textIcon text="历史评分"></textIcon>
              <div class="health-charts-his health-bgc-con flex1 mart11" id="health-his" v-if="!noHistory"></div>
              <no-data v-else></no-data>
            </div>
          </div>
          <div class="health-charts-right flex1 health-bgc">
            <div class="flex1 box health-charts-left-item">
              <textIcon text="权重分布"></textIcon>
              <div class="health-charts-his health-bgc-con flex1" id="health-weight" v-if="!noChartsData"></div>
              <no-data v-else></no-data>
            </div>
          </div>
        </div>
      </div>
      <div class="healty-con-middle">
        <div class="health-charts-right health-bgc">
          <textIcon text="健康监测评分"></textIcon>
          <div class="health-charts-his health-bgc-con">
            <div class="health-charts-tree">
              <el-tree ref="tree1" :data="data1" :default-expand-all="true" :props="defaultProps1" node-key="id" :current-node-key="currentNodeKey" :highlight-current="true" @node-click="handleNodeClick">
                <span class="custom-tree-node textL" slot-scope="{ node, data }" style="width: 100%">
                  <!-- <span class="fuColor">{{data.fu}}</span>
                                  <span class="colorTitle1">{{data.typeName}}</span>
                                  <span v-if="data.count">({{data.count}})</span>
                                  <span class="colorTitle2" style="margin-top:10px">
                                      {{ data.name }}
                                  </span>
                                  <div class="colorTitle3 fs12" style="margin-bottom:5px">{{data.lastModifyTime}}</div> -->
                  <div class="box justify" style="width: 100%">
                    <span>{{ data.name }}</span>
                    <!-- <span v-if="parentId == '0'" style="color:#ccc">{{data.name}}</span> -->
                    <span class="health-tree-code-text">{{ data.grade.toFixed(2) }} <span style="margin-left: 4px">分</span> </span>
                  </div>
                  <!-- <div>
                                      <div>{{node.name}}</div>
                                  </div> -->

                  <!-- <span v-if="data.parentId=='0'">({{data.children.length}})</span> -->
                </span>
              </el-tree>
            </div>
          </div>
        </div>
      </div>
      <div class="healty-con-right">
        <el-table v-if="tableData.length" :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%">
          <el-table-column align="center" prop="name" label="名称" :show-overflow-tooltip="true"> </el-table-column>

          <el-table-column align="center" prop="timeW" label="时间"> </el-table-column>

          <el-table-column align="center" prop="score" label="分数" :show-overflow-tooltip="true"> </el-table-column>

          <el-table-column align="center" width="128px" prop="" label="操作">
            <template slot-scope="scope">
              <span type="text" class="colorText mR20 pointer" size="small" @click="check(scope.row.id)" :class="!myOption ? 'disable_icon' : ''">查看</span>
              <span type="text" class="colorText pointer" size="small" @click="edit(scope.row.id)" :class="!myOption ? 'disable_icon' : ''">修改</span>
            </template>
          </el-table-column>
          <!-- <div slot="empty">
          <p>
            <span class="span">暂无信息</span>
          </p>
        </div> -->
        </el-table>

        <div class="noDataMain health-empty" v-else>
          <img src="../../../assets/images/noData.png" alt="" />
          <span>暂无数据</span>
        </div>

        <!-- <div class="fs14 mT10">桥梁展示健康监测评分为上月评分</div> -->
        <div v-if="tableData.length" class="pagination-bottom">
          <common-pagination :total="total" :pageSize="10" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonPagination from "@/components/Pagination";
import echarts from "echarts";
import textIcon from "../../deviation/components/titleIcon.vue";
import noData from "@/components/noData";
export default {
  name: "healthIndex",
  components: {
    CommonPagination,
    textIcon,
    noData
  },
  data() {
    return {
      currentNodeKey: "",
      defaultProps1: {
        children: "children",
        label: "name"
      },
      healthyTestTitle: "",
      projectId: "",
      tableData: [],
      // 分页
      total: 0,
      currentPage: 1,
      totalScore: 0,
      active: "1",
      noHistory: false,
      noChartsData: false,
      data1: [],
      list: [
        { id: "1", name: "月度", path: "" },
        { id: "2", name: "季度", path: "" },
        { id: "3", name: "年度", path: "" }
      ],
      constructData: {}
    };
  },
  //创建
  created() {},

  //安装
  mounted() {
    this.currentPage = Number(this.$route.query.currentPage) || 1;
    this.active = this.$route.query.active || "1";
    this.setBreadcrumb(this.list[this.active - 1]);
    this.projectId = sessionStorage.getItem("projectId");
    this.totalShow();
  },

  //方法
  methods: {
    totalShow() {
      this.dataList(); //获取列表
      this.showData(); //图表显示
      this.showHealthWeight();
      this.getPandectGrade();
      this.getScatter();
    },
    handleNodeClick(data) {
      console.log("data", data);
      //   if (this.tabActive == 1) {
      //     this.$bus.$emit("centerTarget", data.id);
      //   }
    },
    getScatter() {
      this.$axios.get(`${this.baseURL}health/weight/${this.projectId}`).then((res) => {
        console.log("权重分布", res);
        if (res.data.status == "200") {
          // let arr = res.data.data,
          //   xData = [],
          //   y1Data = [],
          //   y2Data = [];
          // // 2022.09.09 liang修改
          // arr.forEach((item) => {
          //   xData.push(new Date(item.date));
          //   if (item.deviationRate && item.deviationRate.length) {
          //     item.deviationRate.forEach((eve) => {
          //       // xData.push(eve.deviationDate);
          //       if (eve.targetType == 1) {
          //         // console.log("eve", eve);
          //         y1Data.push([eve.deviationDate, parseFloat(eve.deviationRate)]);
          //         // y1Data.push(parseFloat(eve.deviationRate));
          //       } else {
          //         y2Data.push([eve.deviationDate, parseFloat(eve.deviationRate)]);
          //         // y2Data.push(parseFloat(eve.deviationRate));
          //       }
          //     });
          //   }
          // });

          // this.drawSctter();

          // for (let i = 0; i < arr.length; i++) {
          //   if (arr[i].targetType == 1) {
          //     y1Data.push([arr[i].deviationDate, parseFloat(arr[i].deviationRate)]);
          //   } else {
          //     y2Data.push([arr[i].deviationDate, parseFloat(arr[i].deviationRate)]);
          //   }
          // }
          // console.log("y1data", y1Data, "y2data", y2Data, "xdata", xData);
          // if (!y1Data.length && !y2Data.length) {
          //   this.distribution = true;
          //   return;
          // }
          // console.log("---------------------", xData, y1Data, y2Data);

          if (!res.data.data || !res.data.data.length) {
            this.noChartsData = true;
          }
          this.noChartsData = false;
          document.getElementById("health-weight").removeAttribute("_echarts_instance_");
          this.$nextTick(() => {
            this.$chart3.weightDistribution("health-weight", res.data.data);
          });
        }
      });
    },
    getPandectGrade() {
      let data = [{}];
      this.$axios.post(`${this.baseURL}pandect/grade/info/${this.projectId}`, data).then((res) => {
        console.log("健康监测评分", res);
        if (res.data.status == 200) {
          this.data1 = res.data.data;
        }
      });
    },
    setBreadcrumb(item) {
      console.log("点击三级菜单", item);
      let arr = this.$store.state.breadcrumbList;
      if (arr.length > 1) {
        arr.splice(1);
      }
      arr.push(item);
      this.$store.commit("getBreadcrumb", arr);
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    //查看
    check(id) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }

      console.log("id", id);
      this.$router.push({
        path: "healthySet",
        query: {
          type: "check",
          id: id,
          currentPage: this.currentPage,
          active: this.active
        }
      });
      this.list[this.active - 1].path = `/healthIndex?currentPage=${this.currentPage}&active=${this.active}`;
      this.setBreadcrumb(this.list[this.active - 1]);
    },

    // 编辑
    edit(id) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }

      this.$router.push({
        path: "healthySet",
        query: {
          type: "edit",
          id: id,
          currentPage: this.currentPage,
          active: this.active
        }
      });
      this.list[this.active - 1].path = `/healthIndex?currentPage=${this.currentPage}&active=${this.active}`;
      this.setBreadcrumb(this.list[this.active - 1]);
    },

    activeTab(item) {
      console.log("e", item);
      this.currentPage = 1;
      this.$router.push({ path: "/healthIndex", query: { currentPage: 1, active: item.id } });
      this.active = item.id;
      item.path = `/healthIndex?currentPage=1&active=${item.id}`;
      this.setBreadcrumb(item);
      //   this.dataList();
      //   this.showData();
      //   this.showHealthWeight();
      this.totalShow();
    },

    // 分页事件
    handleCurrentChange(val) {
      this.currentPage = val;
      this.$router.push({
        path: "healthIndex",
        query: {
          currentPage: this.currentPage,
          active: this.active
        }
      });
      this.dataList();
    },

    dataList() {
      this.$axios.get(`${this.baseURL}health/page?pageNum=${this.currentPage}&pageSize=10&projectId=${this.projectId}&type=${this.active}`).then((res) => {
        console.log("获取列表", res.data.data.records);
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    getSet() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }

      this.$router.push("/healthySet");
      this.list[this.active - 1].path = `/healthIndex?currentPage=${this.currentPage}&active=${this.active}`;
      this.setBreadcrumb(this.list[this.active - 1]);
    },

    // 显示数据  获取月度、季度、年度数据   6个月  图表
    showData() {
      let params = {
        type: this.active,
        size: 6
      };
      console.log("params", params);
      this.$axios.get(`${this.baseURL}health/six/his/${this.projectId}`, { params }).then((res) => {
        console.log("获取图表数据kkkk", res.data);

        let arr = res.data.data,
          month = [],
          bdci = [],
          sbci = [],
          spci = [],
          total = [],
          grade = [],
          regular = [],
          n = res.data.data.length;
        if (!n) {
          this.noHistory = true;
          // this.$nextTick(() => {
          //   this.$chart1.columnar("health-his", month, bdci, sbci, spci, total, showed);
          // })

          return;
        }
        this.noHistory = false;

        for (let i = 0; i < n; i++) {
          month.push(arr[i].timeW);
          bdci.push(arr[i].bdci);
          sbci.push(arr[i].sbci);
          spci.push(arr[i].spci);
          grade.push(arr[i].grade);
          regular.push(arr[i].regularScore);
          // let k = arr[i].bdci + arr[i].sbci + arr[i].spci;
          // total.push(k);
          total.push(arr[i].grade);
        }
        console.log("month", month, "bdci", bdci, "sbci", sbci, "spci", spci, "totalScore", this.totalScore);
        this.healthyTestTitle = month[0];
        var showed = false;
        this.$nextTick(() => {
          // this.$chart1.columnar("health-his", month, bdci, sbci, spci, total, showed);
          this.$chart1.healthHis("health-his", month, grade, regular);
        });
      });
    },
    //  健康监测及权重图   查询最新结构数据
    showHealthWeight() {
      let params = {
        type: this.active
      };
      this.$axios.get(`${this.baseURL}health/last/his/${this.projectId}`, { params }).then((res) => {
        console.log("查询最新数据", res.data.data);
        this.constructData = res.data.data;
        // this.constructData.regular.score = "";

        // this.constructData.regular.spci = "";
        // this.constructData.regular.bdci = "";
        // this.constructData.regular.sbci = "";

        this.totalScore = res.data.data.technical;
        let arr1 = res.data.data.sbcilist,
          arr2 = res.data.data.spcilist,
          arr1data = [],
          arr2data = [],
          name2 = "上部结构权重",
          name1 = "下部结构权重";

        // if (!arr1 || !arr1.length || !arr2 || !arr2.length) {
        //   this.noChartsData = true;
        //   return;
        // }
        // this.noChartsData = false;
        let arrcolor = [
          [
            { offset: 0, color: "rgba(255, 242, 126, 0.8)" },
            { offset: 1, color: "rgba(252, 240, 53, 0.2)" }
          ],
          [
            { offset: 0, color: "rgba(252, 182, 53, 0.8)" },
            { offset: 1, color: "rgba(252, 182, 53, 0.2)" }
          ],
          [
            { offset: 0, color: "rgba(66, 252, 150, 0.8)" },
            { offset: 1, color: "rgba(66, 252, 150, 0.2)" }
          ],
          [
            { offset: 0, color: "rgba(91, 252, 240, 0.8)" },
            { offset: 1, color: "rgba(91, 234, 252, 0.2)" }
          ],
          [
            { offset: 0, color: "rgba(91, 164, 252, 0.8)" },
            { offset: 1, color: "rgba(91, 164, 252, 0.2)" }
          ],
          [
            { offset: 0, color: "rgba(112, 103, 252, 0.8)" },
            { offset: 1, color: "rgba(103, 115, 252, 0.2)" }
          ],
          [
            { offset: 0, color: "rgba(162, 53, 255, 0.8)" },
            { offset: 1, color: "rgba(252, 103, 249, 0.2)" }
          ],
          [
            { offset: 0, color: "rgba(252, 103, 197, 0.8)" },
            { offset: 1, color: "rgba(251, 103, 252, 0.2)" }
          ],
          [
            { offset: 0, color: "rgba(252, 117, 103, 0.8)" },
            { offset: 1, color: "rgba(252, 117, 103, 0.2)" }
          ],
          [
            { offset: 0, color: "rgba(254, 128, 94, 0.8)" },
            { offset: 1, color: "rgba(252, 155, 103, 0.2)" }
          ]
        ];

        for (let i = 0, n = arr1 && arr1.length ? arr1.length : 0; i < n; i++) {
          let obj = {
            name: arr1[i].systemName,
            value: arr1[i].systemFactor,
            label: {
              normal: {
                color: "#fff"
              }
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 1, 1, 0, arrcolor[i])
              }
            }
          };
          arr1data.push({ ...obj });
        }
        for (let j = 0, m = arr2 && arr2.length ? arr2.length : 0; j < m; j++) {
          let obj = {
            name: arr2[j].systemName,
            value: arr2[j].systemFactor,
            label: {
              normal: {
                color: "#fff"
              }
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 1, 1, 0, arrcolor[j])
              }
            }
          };
          arr2data.push({ ...obj });
        }

        // this.$nextTick(() => {
        //   console.log("=========");
        //   this.$chart1.healthyBar("chart-top-weight", name2, arr2data);
        //   this.$chart1.healthyBar("chart-bottom-weight", name1, arr1data);
        // });
      });
    }
  }
};
</script>
<style scoped lang="scss">
.healthyIndex {
  overflow-y: hidden;
  margin-top: 20px;
  .health-btn {
    background-color: transparent;
  }

  .healty-con {
    display: flex;
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    .healty-con-middle {
      width: 20vw;
    }
    .healty-con-left {
      width: 48vw;
      // flex: 1;
      // margin-right: 26px;
      display: flex;
      flex-direction: column;
    }
    .healty-con-right {
      width: 28vw;
      position: relative;
      .pagination-bottom {
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
      }
    }
  }
  .health-tree-code-text {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #73d0ff;
    padding-right: 15px;
  }
  .health-bgc {
    border-radius: 12px;
    background-color: rgba(8, 32, 76, 0.3);
    padding: 24px 24px 24px 29px;
    box-sizing: border-box;
  }
  .health-bgc-con {
    background-image: radial-gradient(circle at 50% 53%, #01042c 0%, #0b2d5a 97%);
    border-radius: 12px;
    padding: 25px 25px 17px 25px;
  }
  .health-charts {
    // flex: 1;
    margin-top: 12px;
    display: flex;

    height: 100%;
    overflow: hidden;
    // height: calc(100% - 261px);
  }
  .health-charts-left {
    margin-right: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .health-charts-his {
    // flex: 1;
    margin-top: 11px;
    height: 100%;
    overflow: hidden;

    ::v-deep .el-tree {
      background-color: transparent;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
    }
    ::v-deep .el-tree-node__expand-icon:not(.is-leaf) {
      color: #28a0d8;
    }
    ::v-deep .el-tree-node .el-tree-node__content {
      margin-bottom: 20px;
    }
    .health-charts-tree {
      height: calc(100% - 1px);
      overflow-y: auto;
    }
  }
  .mart11 {
    margin-bottom: 11px;
  }
  .h86 {
    height: calc(100% - 86px);
    overflow-y: auto;
  }
  .h90 {
    height: calc(100% - 90px);
  }
  .health-charts-left-item {
    flex-direction: column;
  }
  .health-charts-right {
    // width: 470px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .health-month {
    .health-month-con {
      display: flex;
      margin-top: 11px;
      .w336 {
        width: 336px;
      }
      .health-month-con-item {
        .health-month-con-item-img {
          width: 101px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .health-month-con-status {
          width: 101px;
          height: 101px;
        }
        .health-month-con-item-text {
          flex: 1;
        }
      }
      .line {
        border-left: 1px solid #08204c;

        margin-left: 20px;
        margin-right: 20px;
        margin-top: 50px;
        height: 50px;
      }
    }
  }
}
.w86 {
  width: 86px;
}
.w90 {
  width: 90px;
}
.health-month-con-item-first {
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 20px;
  color: #cbe1fc;
  .health-month-con-item-first-t {
    // width: 104px;
    flex: 1;
  }
  .health-month-con-item-first-score {
    font-family: PingFangSC-Medium;
    font-weight: 500;
    font-size: 28px;
    color: #73d0ff;
    // flex: 1;
    .score {
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #73d0ff;
    }
    .health-date {
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 12px;
      color: #bfcee8;
      letter-spacing: 0;
    }
  }
}

.health-month-con-item-second {
  margin-top: 8px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 14px;
  color: #cbe1fc;
  align-items: center;
  .health-month-con-item-second-t {
    // width: 104px;
    flex: 1;
  }
  .health-month-con-item-second-score {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 20px;
    color: #73d0ff;
    .score {
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #73d0ff;
    }
  }
}

#history-health {
  padding-top: 10px;
}

.no-his-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #64757e;
  font-size: 18px;
}

.h100 {
  width: 100%;
  height: 100%;
}

.h270 {
  height: 270px;
}

.charts-h {
  height: 306px;
}
.charts-position {
  position: relative;
  background-color: rgba(0, 0, 0, 0.16);

  .charts-title {
    padding-left: 40px;
    background: url("../../../assets/images/healthy/home_icon_title.png") no-repeat 8px center;
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}

.chart-line {
  position: relative;
}

.chart-line::before,
.chart-line::after {
  content: "";
  position: absolute;
  height: 151px;
  top: 69px;
  border-left: 1px solid #3a4a7b;
}

.chart-line::after {
  right: 0;
}

.chart-status {
  width: 183px;
  margin: 69px auto 0;

  padding-top: 201px;
  padding-right: 26px;
  box-sizing: border-box;
}

.charts-b-t {
  font-size: 18px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}

.chart-status-1 {
  background: url("../../../assets/images/healthy/yilei_icon_png.png") no-repeat center top;
  background-size: cover;
}

.chart-status-2 {
  background: url("../../../assets/images/healthy/erlei_icon_png.png") no-repeat center top;
  background-size: cover;
}

.chart-status-3 {
  background: url("../../../assets/images/healthy/sanlei_icon_png.png") no-repeat center top;
  background-size: cover;
}

.chart-status-4 {
  background: url("../../../assets/images/healthy/silei_icon_png.png") no-repeat center top;
  background-size: cover;
}

.chart-status-5 {
  background: url("../../../assets/images/healthy/wulei_icon_png.png") no-repeat center top;
  background-size: cover;
}

.jieduan {
  width: 100%;
  height: 1px;
  box-shadow: 0px -1px 4px 0px #ffffff;
  opacity: 0.32;
  border: 1px solid #1f61ff;
  margin-bottom: 10px;
}

.ulTab {
  .fu {
    position: relative;
  }

  li {
    position: relative;
    cursor: pointer;
  }

  .tiao {
    position: absolute;
    top: 20px;
    width: 100%;
    height: 8px;
    background: url("../../../assets/images/report/juxing.png");
    background-size: 100% 100%;
    // background-position: 50px 0;
  }

  .shu {
    margin: 0 30px;
    width: 1px;
    height: 20px;
    background: #ccc;
  }
}

#columnBox {
  width: 100%;
  // height: 100%;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}

.health-empty {
  // transform: translateY(-100px)
}
</style>
