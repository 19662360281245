<template>
  <span class="title-icon" :style="{ 'font-size': fontSize + 'px' }">{{ text }}</span>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    },
    fontSize: {
      type: Number,
      default: 20
    }
  }
};
</script>
<style lang="scss" scoped>
.title-icon {
  display: inline-block;
  position: relative;
  padding-left: 12px;

  &::before {
    content: "";
    width: 4px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -4px;
    background: rgba(114, 214, 243, 1);
    // background-image: linear-gradient(217deg, rgba(62, 181, 178, 0.35) 0%, rgba(0, 0, 0, 0.18) 100%);
  }
}
</style>
